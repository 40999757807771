document.addEventListener('DOMContentLoaded', function() {
    /**
     * Parāda/paslēpj valodas izvēlni.
     */
    document.getElementById('language-button').addEventListener('click', function () {
        const currentDisplayRule = document.getElementById('language-button-dropdown').style.display

        document.getElementById('language-button-dropdown').style.display = currentDisplayRule == 'block' ? 'none' : 'block'
    })

    /**
     * Parāda/paslēpj profila dropdown lodziņu.
     */
    if (document.getElementById('profile-button')) {
        document.getElementById('profile-button').addEventListener('click', function () {
            const currentDisplayRule = document.getElementById('profile-button-dropdown').style.display

            document.getElementById('profile-button-dropdown').style.display = currentDisplayRule == 'block' ? 'none' : 'block'
        })
    }
    
    /**
     * Parāda/paslēpj zvana dropdown lodziņu.
     */
    if (document.getElementById('phone-button')) {
        document.getElementById('phone-button').addEventListener('click', function () {
            const currentDisplayRule = document.getElementById('phone-button-dropdown').style.display

            document.getElementById('phone-button-dropdown').style.display = currentDisplayRule == 'block' ? 'none' : 'block'
        })
    }

    /**
     * Paslēpj dropdown lodziņus, kad lietotājs kaut kur uzklikšķina.
     */
    document.body.addEventListener('click', function (event) {
        if (! event.target.closest('#language-button')) {
            document.getElementById('language-button-dropdown').style.display = 'none'
        }

        const profileDropdown = document.getElementById('profile-button-dropdown')
        const phoneDropdown = document.getElementById('phone-button-dropdown')

        if (profileDropdown && !event.target.closest('#profile-button')) {
            profileDropdown.style.display = 'none'

        } if (phoneDropdown && !event.target.closest('#phone-button')) {
            phoneDropdown.style.display = 'none'
        }
    }, true)
})
